class ShowPlatformUserCreate
{
    static init(formName)
    {
        this.newMethod();
        this.validate(formName);
    }

    static newMethod()
    {
        jQuery.validator.addMethod('companyUnique', function (value) {
            var isSuccess = '';

            jQuery.ajax({
                url: "/users/",
                type: "get",
                dataType: 'json',
                async: false,
                data: {
                    // 5 - company_name
                    'columns[5][data]': 'company_name',
                    'columns[5][search][value]': function () {
                        return jQuery("#company_name").val();
                    }
                },
                success: function (data) {
                    if (data.data.length > 0) {
                        isSuccess = false;
                    } else {
                        isSuccess = true;
                    }
                }
            });

            return isSuccess;
        }, '');
        
        jQuery.validator.addMethod("alpha_num", function(value, element) {
            return this.optional(element) || /^[a-zA-Z0-9]+$/i.test(value);
        }, "The podcast name must only contain letters and numbers.");

    }

    static validate(formName) {
        var rules = {
            company_name: {
                required: function () {
                    if (jQuery('#company_name_checkbox').prop('checked')) {
                        return true;
                    } else {
                        return false;
                    }
                },
                companyUnique: function () {
                    if (jQuery('#company_name_checkbox').prop('checked')) {
                        return true;
                    } else {
                        return false;
                    }
                },
                alpha_num: function () {
                    if (jQuery('#company_name_checkbox').prop('checked')) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        };

        var messages = {
            company_name: {
                required: "Podcast Name is Required.",
                companyUnique: 'Podcast Name already in use.',
                alpha_num: 'The podcast name must only contain letters and numbers.'
            }
        };

        var options = {
            rules: rules,
            messages: messages,
            errorClass: 'text-danger'
        };

        jQuery(formName).validate(options);
    }

}

module.exports = ShowPlatformUserCreate;