
class ShowPlatformHeader
{

    static init()
    {
        this.setBreadcrumb();
        this.setUserName();
        this.setDropDown();
        this.leftBarToggle();
    }

    static setBreadcrumb()
    {
        var activeMenu = $('.sidebar .navigationbar .vertical-menu-detail .active .vertical-menu .nav-item .active').text();
        var breadcrumb = '<li class="breadcrumb-item">'
                + '<a href="/">'
                + '<i class="sp-ShowShops text-primary-dark"></i>'
                + '<span class="text-primary-dark">Login</span>'
                + '</a>'
                + '</li>';

        breadcrumb += '<li class="breadcrumb-item"><a href="#"><i class=""></i>' + activeMenu + '</a></li>';

        jQuery(".breadcrumb").html(breadcrumb);
    }

    static setUserName()
    {
        if (typeof spUserName !== 'undefined') {
            jQuery("#user-menu .user-image").prop("title", spUserName);
        }
    }

    static setDropDown()
    {
        if (typeof spUserId !== 'undefined') {
            var dropdownValues = '';
            if (spUserRoleIsAdmin == '1') {
                dropdownValues += '<a class="dropdown-item" href="/users/' + spUserId + '/edit">Edit Profile</a>';
            }
            dropdownValues += '<a class="dropdown-item" href="/logout">Logout</a>';

            jQuery("#user-menu .dropdown-menu").html(dropdownValues);
        }
    }

    static leftBarToggle()
    {
        jQuery("#leftBarClick, #leftBarClickSideBar").click(function () {
            jQuery(".leftbar").toggleClass("leftbarToggle");
        });

    }

}

module.exports = ShowPlatformHeader;